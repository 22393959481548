import { axios_instance } from './BaseApi'
export type SendChatDTO = {
  fileUUID: string
  prompt: string
}
/**@TODO define types */
export const getPodcastByUuid = async (uuid: string) => {
  const { data } = await axios_instance.get(`podcast/${uuid}`)

  return data
}

export const initializeUpload = async (uploadInput: any) => {
  const { data } = await axios_instance.post(
    '/podcast/initializeMultipartUpload',
    uploadInput,
  )
  return data
}

export const finalizeMultipartUpload = async (uploadInput: any) => {
  const { data } = await axios_instance.post(
    '/shows/finalizeMultipartUpload',
    uploadInput,
  )
  return data
}

export async function getPodcastsForUser(
  show_id: number | string | undefined,
  offset: number,
  limit: number,
) {
  if (!show_id) {
    show_id = 'default'
  }
  const { data } = await axios_instance.get(
    `shows/${show_id}/user?offset=${offset}&limit=${limit}`,
  )
  return data
}

export async function getPodcastTranscriptionFormatted(
  file_uuid: string,
  type: string,
) {
  const response = await axios_instance.get(`podcast/${file_uuid}/${type}`, {
    responseType: 'blob',
  })
  return response
}

export async function deletePodcastEpisode(file_uuids: string[]) {
  const promises = await Promise.allSettled(
    file_uuids.map((file_uuid) =>
      axios_instance.delete(`podcast/${file_uuid}`),
    ),
  )
  return promises
}

export const sendChat = async (chatData: SendChatDTO) => {
  const { data } = await axios_instance.post(`gpt/chat`, {
    fileUUID: chatData.fileUUID,
    prompt: chatData.prompt,
  })

  return data
}
