import {
  FormControl,
  Link,
  Input,
  FormErrorMessage,
  Center,
  Heading,
  VStack,
  Text,
  Box,
  Spinner,
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { useState, useCallback, useEffect } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { searchListenNotes } from '../../api'
import { PodcastResult, SearchResults } from './SearchResults'
type FormDataStepTwo = {
  query: string
}
export type FormErrorsStepTwo = FieldErrors<FormDataStepTwo>

export function SearchPodcast({
  setSelectedPodcast,
  setStep,
  setManualShow,
}: {
  setSelectedPodcast: React.SetStateAction<any>
  goBack: () => void
  setStep: React.SetStateAction<any>
  isDisabled: boolean
  setManualShow: () => void
}) {
  const [podcastResults, setPodcastResults] = useState<PodcastResult[]>([])
  const [query, setQuery] = useState<string>('')
  const [abortController, setAbortController] =
    useState<AbortController | null>()
  const {
    register,
    formState: { errors },
  } = useForm<any>()

  const [isSearching, setIsSearching] = useState(false)
  const goBackToSearch = useCallback(() => {
    setPodcastResults([])
  }, [])

  const search = useCallback(
    async (data: string) => {
      const query = data
      if (abortController) {
        abortController.abort()
        setAbortController(null)
        setIsSearching(false)
      }
      if (!query) {
        setIsSearching(false)
        setPodcastResults([])
        return
      }
      setIsSearching(true)
      const response = searchListenNotes(query)
      setAbortController(response.controller)
      const res = await response.response
      const podcast_results = res.data.results.map(
        (podcast: {
          title_original: string
          publisher_original: string
          description_original: string
          image: string
          id: string
          total_episodes: number
        }) => ({
          id: podcast.id,
          title: podcast.title_original,
          publisher: podcast.publisher_original,
          description: podcast.description_original,
          image: podcast.image,
          total_episodes: podcast.total_episodes,
        }),
      )
      setAbortController(null)
      setIsSearching(false)
      setPodcastResults(podcast_results)
    },
    [abortController],
  )

  const debouncedSearch = useCallback(debounce(search, 1000), [])
  const immediateSearch = useCallback(search, [])
  useEffect(() => {
    if (query) {
      debouncedSearch(query)
    } else {
      immediateSearch(query)
    }
  }, [immediateSearch, query, debouncedSearch])

  const error = errors as FormErrorsStepTwo

  return (
    <VStack w="400px" align="center">
      <Heading fontSize="xl" pb={0} mb={0}>
        Let's find your podcast!
      </Heading>
      <Text mt="4" fontSize="sm" color="gray.400" align="center">
        This uses{' '}
        <a style={{ fontWeight: 'bold' }} href="http://listennotes.com/">
          Listen Notes
        </a>{' '}
        to find data about your podcast.
        <br /> Not public yet?{' '}
        <Link fontWeight="bold" onClick={setManualShow} cursor={'pointer'}>
          Add your show here
        </Link>
      </Text>
      <FormControl isInvalid={!!error.query}>
        <Input
          mt="20px"
          type="text"
          id="query"
          placeholder="Your podcast name"
          {...register('query', {
            required: 'You must enter a podcast name!',
            onChange: (event: any) => setQuery(event.target.value),
          })}
        />
        <FormErrorMessage>{error.query?.message}</FormErrorMessage>
      </FormControl>
      {podcastResults.length || isSearching ? (
        <Box boxShadow="lg" w="100%" borderWidth="1px" borderRadius="6px">
          {isSearching ? (
            <Center p="4px 0" h="60px">
              <Spinner />
            </Center>
          ) : (
            <SearchResults
              podcastResults={podcastResults}
              setSelectedPodcast={setSelectedPodcast}
              goBack={goBackToSearch}
              setStep={setStep}
            />
          )}
        </Box>
      ) : null}
    </VStack>
  )
}
