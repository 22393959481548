import { VStack, HStack, Button, Text, Box } from '@chakra-ui/react'
import { CiStar } from 'react-icons/ci'
import { Logo } from './Logo'
import { ProfileContext } from './shared/ProfileContext'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Show } from '../api'
import { showState } from '../store/Show'

export function AuthNavContainer({
  user,
  titleText,
  children,
  NavContent,
  showDashboardNav,
}: {
  user: any
  titleText?: string
  NavContent?: React.ComponentType<any>
  children: any
  showDashboardNav?: boolean
}) {
  const navigate = useNavigate()
  const [currentShow] = useRecoilState<Show>(showState)
  return (
    <VStack mb={50} w={'100%'} maxWidth={'100%'} bg="gray.50" p="8px">
      <HStack w="100%" h="43px" justify="space-between" p="20px">
        <HStack align="flex-start">
          <Logo width="24px" />
          <Text fontWeight="bold">Listener.fm</Text>
        </HStack>
        {titleText ? (
          <Text>{titleText}</Text>
        ) : NavContent ? (
          <NavContent />
        ) : null}
        <HStack>
          {user?.billingState !== 'active' ? (
            <Link to="/subscribe">
              <Button
                background="white"
                borderWidth="1px"
                boxShadow="sm"
                h="32px"
                padding="12px"
                fontSize="14px"
                leftIcon={<CiStar width="20px" />}
              >
                Upgrade
              </Button>
            </Link>
          ) : null}
          {showDashboardNav ? (
            <Button
              background="white"
              borderWidth="1px"
              boxShadow="sm"
              h="32px"
              padding="12px"
              fontSize="14px"
              onClick={() =>
                navigate(`/dashboard/${currentShow ? currentShow.id : ''}`)
              }
            >
              Return Home
            </Button>
          ) : null}
          <ProfileContext user={user} />
        </HStack>
      </HStack>
      <Box
        w="100%"
        bg="white"
        boxShadow="md"
        borderRadius="12px"
        borderWidth="1px"
        borderColor="gray.100"
        pb="40px"
        minH="lg"
      >
        {children}
      </Box>
    </VStack>
  )
}
